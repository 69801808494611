/**
 * Sets a cookie with the given name, value, and optional options
 * @param {string} cookieName - the name of the cookie to set
 * @param {string} cookieValue - the value to set for the cookie
 * @param {number} daysToExpire - optional number of days until the cookie expires
 */
export const setCookie = (
  cookieName: string,
  cookieValue: string,
  daysToExpire: number
) => {
  let cookieString = `${cookieName}=${cookieValue}`

  if (daysToExpire) {
    const expirationDate = new Date()
    expirationDate.setDate(expirationDate.getDate() + daysToExpire)
    cookieString += `; expires=${expirationDate.toUTCString()}`
  }

  // Set cookie as session cookie if no expiration specified
  if (!daysToExpire) {
    cookieString += '; path=/; expires=0'
  }

  document.cookie = cookieString
}

/**
 * Gets the value of a cookie by its name
 * @param {string} cookieName - the name of the cookie to retrieve
 * @returns {object|undefined} an object with the cookie's name and value, or undefined if not found
 */
export const getCookie = (cookieName: string): object | undefined => {
  return document.cookie
    ?.split('; ')
    .map((cookie) => cookie.split('='))
    .map((cookie) => ({ name: cookie[0], value: cookie[1] }))
    .find(({ name }) => cookieName === name)
}
